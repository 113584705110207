import React, { Component } from "react"
import styled from "styled-components"
import "./App.css"

const StyledPage = styled.div`
    background: white;
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  
    img.emoji {
        width: 10%;
        min-width: 250px;
    }
  
    img.logo {
        margin-top: 48px;
        width: 20%;
        min-width: 400px;
    }
  
    img {
        -webkit-user-drag: none;
        user-select: none;
    }
`

export default class extends Component {
    render() {
        return <StyledPage>
            <img src="/1.png" className="emoji" />
            <img src="/logo.svg" className="logo" />
        </StyledPage>
    }
}